import React, { useState, useEffect } from 'react';
import Axios from '../../config/axios';
import 'antd/dist/antd.css';
import { Select } from 'antd';
import { useForm, Controller } from "react-hook-form";
import Card from 'react-bootstrap/Card';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import Moment from 'moment';
import statesList from '../Helpers/StatesList';
import { toast, confirm } from '@rickylandino/react-messages';

export default function MROldRequest(props) {
    const { Option } = Select;

    const [state, setState] = useState({
        allContractors: [],
        allJobs: [],
        selectedContractorId: '',
        contractorContacts: [],
        contactsDisabled: true,
        contactsPlaceHolder: 'Select Contractor To Display Contacts',
        selectedContactId: null,
        formFields: {},
        states: statesList,
        contractorsLoaded: false
    });

    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    useEffect(() => {
        //LoadTestData();

        Axios.get(`/api/InitializeOldRequest`, {
            params: {

            }
        }).then(response => {
            //console.log(response.data);
            setState({
                ...state,
                allContractors: response.data.contractors,
                allJobs: response.data.jobs
            });
        }).catch(error => {
            console.log(error);
        });

    }, [props]);

    function onContractorChange(value) {
        setValue('formFields.contractors_ID', value)

        if (value !== '') {
            getContractorContacts(value);
        }
        else {
            setState({
                ...state,
                contactsPlaceHolder: 'Select Contractor To Display Contacts',
                contractorContacts: [],
                selectedContactId: null,
                contactsDisabled: true
            });
        }
    }

    function onSearch(val) {
        //console.log('search:', val);
    }

    function onContactChange(value) {
        //console.log(`selected contact ${value}`);

        setValue('formFields.contacts_ID', value);
        setState({
            ...state,
            selectedContactId: value
        });
    }

    function onJobChange(value) {
        setValue('formFields.jobs_ID', value)
    }

    //useEffect(() => {
    //    getAllContractors();

    //    setValue('formFields', {});

    //    //LoadTestData();

    //    //const subscription = watch((value, { name, type }) => {
    //    //    //console.log(value);
    //    //    //console.log(name);
    //    //    //console.log(type);
    //    //});

    //    //return () => subscription.unsubscribe();

    //}, [props]);

    //useEffect(() => {
    //    if (state.contractorsLoaded === true) {
    //        getAllJobs();
    //    }

    //}, [state.contractorsLoaded]);

    //function getAllContractors() {
    //    Axios.get(`/api/GetAllContractors`, {
    //        params: {

    //        }
    //    }).then(response => {
    //        //console.log(response.data);
    //        setState({
    //            ...state,
    //            allContractors: response.data,
    //            contractorsLoaded: true
    //        });
    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    //function getAllJobs() {
    //    console.log('here in getAllJobs');

    //    Axios.get(`/api/GetAllJobs`, {
    //        params: {

    //        }
    //    }).then(response => {
    //        console.log(response.data);
    //        setState({
    //            ...state,
    //            allJobs: response.data
    //        });
    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    function getContractorContacts(selectedContractorId) {
        Axios.get(`/api/GetContractorContacts`, {
            params: {
                contractorID: selectedContractorId
            }
        }).then(response => {
            let contactsList = response.data;

            var contactsDisabled;
            var contactsPlaceHolder;

            if (contactsList.length > 0) {
                contactsDisabled = false;
                contactsPlaceHolder = 'Select Contact';
            }
            else {
                contactsDisabled = true;
                contactsPlaceHolder = 'No Contacts Found';
            }

            setState({
                ...state,
                contractorContacts: contactsList,
                selectedContractorId: selectedContractorId,
                contactsDisabled,
                contactsPlaceHolder,
                selectedContactId: null
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function handleDateChange(name, date) {
        //let formFields = { ...this.state.formFields, [name]: date }

        //let disableSaveBtn = this.state.disableSaveBtn;

        //if (formFields.examDateTime &&
        //    formFields.examTypeID &&
        //    formFields.performingTechUserID &&
        //    formFields.insuranceID &&
        //    formFields.providerID) {
        //    disableSaveBtn = false;
        //}

        //this.setState({ formFields, disableSaveBtn }, () => {
        //    if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
        //        this.isDirty = true;  // dirty
        //        Globals.isDirtyPage = true;
        //    }
        //});
    }

    function ClearScreenFields() {
        setValue('formFields.contractors_ID', '')
        setValue('formFields.contacts_ID', '')
        setValue('formFields.bidDate', '');
        setValue('formFields.projectName', '');
        setValue('formFields.projectDetails', '');
        setValue('formFields.jobsiteAddress', '');
        setValue('formFields.city', '');
        setValue('formFields.state', '');
        setValue('formFields.zip', '');
        setValue('formFields.typeOfHours', '');
        setValue('formFields.requestedHours', '');
        setValue('formFields.awardingParty', '');
        setValue('formFields.jobsiteContactPerson', '');
        setValue('formFields.jobsiteContactPhone', '');
        setValue('formFields.workDescription', '');
        setValue('formFields.unionBidder1', '');
        setValue('formFields.nonUnionBidder1', '');
        setValue('formFields.unionBidder2', '');
        setValue('formFields.nonUnionBidder2', '');
        setValue('formFields.unionBidder3', '');
        setValue('formFields.nonUnionBidder3', '');
        setValue('formFields.grantedHours', '');
        setValue('formFields.hoursPaidManually', '');
        setValue('formFields.dollarsPerHour', '');
        setValue('formFields.grantedBy', '');
        setValue('formFields.jobNum', '');
        setValue('formFields.awardDate', null);
        setValue('formFields.expectedStartDate', null);
        setValue('formFields.anticipatedCompletionDate', null);

        setState({
            ...state,
            selectedContactId: ''
        });
    }

    function LoadTestData() {
        setValue('formFields.bidDate', new Date());
        setValue('formFields.projectName', 'Daves Test Project');
        setValue('formFields.projectDetails', 'Building 1 conference room');
        setValue('formFields.jobsiteAddress', '850 Sherman Ave');
        setValue('formFields.city', 'Hamden');
        setValue('formFields.state', 'CT');
        setValue('formFields.zip', '06518');
        setValue('formFields.typeOfHours', 'Plumbing Forman');
        setValue('formFields.requestedHours', '100');
        setValue('formFields.awardingParty', 'Landino Construction');
        setValue('formFields.jobsiteContactPerson', 'DJL');
        setValue('formFields.jobsiteContactPhone', '860-965-9916');
        setValue('formFields.workDescription', 'This is just a test project to test submitting a new request for MR');
        setValue('formFields.unionBidder1', 'Union Co. 1');
        setValue('formFields.nonUnionBidder1', 'Non Union Co. 1');
        setValue('formFields.grantedHours', '30');
        setValue('formFields.dollarsPerHour', '6');
        setValue('formFields.grantedBy', 'DJL');
    }

    function handleAddRequest() {
        setValue('formFields.jobsiteCityState', getValues().formFields.city + ' ' + getValues().formFields.state + ', ' + getValues().formFields.zip);

        let message = "";

        if (getValues().formFields.bidDate === '' || getValues().formFields.bidDate === null || typeof getValues().formFields.bidDate === 'undefined') {
            message += '&bull;&nbsp;Invalid Bid Date specified</br>';
        }
        if (typeof getValues().formFields.contractors_ID === 'undefined' || getValues().formFields.contractors_ID == '') {
            message += '&bull;&nbsp;Contractor is required</br>';
        }
        if (typeof getValues().formFields.contacts_ID === 'undefined' || getValues().formFields.contacts_ID == '') {
            message += '&bull;&nbsp;Contact is required</br>';
        }
        if (typeof getValues().formFields.projectName === 'undefined' || getValues().formFields.projectName == '') {
            message += '&bull;&nbsp;Project Name is required</br>';
        }
        if (typeof getValues().formFields.city === 'undefined' || getValues().formFields.city == '' || typeof getValues().formFields.state === 'undefined' || getValues().formFields.state == '' || typeof getValues().formFields.zip === 'undefined' || getValues().formFields.zip == '') {
            message += '&bull;&nbsp;Jobsite City, State & Zip is required</br>';
        }
        if (typeof getValues().formFields.requestedHours === 'undefined' || getValues().formFields.requestedHours == '') {
            message += '&bull;&nbsp;Requested Hours is required</br>';
        }
        if (typeof getValues().formFields.typeOfHours === 'undefined' || getValues().formFields.typeOfHours == 'Select' || getValues().formFields.typeOfHours == '') {
            message += '&bull;&nbsp;Type of Hours is required</br>';
        }
        if (typeof getValues().formFields.workDescription === 'undefined' || getValues().formFields.workDescription == '') {
            message += '&bull;&nbsp;Work Description is required</br>';
        }
        if (typeof getValues().formFields.awardingParty === 'undefined' || getValues().formFields.awardingParty == '') {
            message += '&bull;&nbsp;Awarding Party is required</br>';
        }
        if (typeof getValues().formFields.unionBidder1 === 'undefined' || getValues().formFields.unionBidder1 == '') {
            message += '&bull;&nbsp;Union Bidder (mimimum of 1 is required)</br>';
        }
        if (typeof getValues().formFields.nonUnionBidder1 === 'undefined' || getValues().formFields.nonUnionBidder1 == '') {
            message += '&bull;&nbsp;Non Union Bidder (mimimum of 1 is required)</br>';
        }
        if (getValues().formFields.awardDate === '' || getValues().formFields.awardDate === null || typeof getValues().formFields.awardDate === 'undefined') {
            message += '&bull;&nbsp;Invalid Award Date specified</br>';
        }
        if (getValues().formFields.expectedStartDate === '' || getValues().formFields.expectedStartDate === null || typeof getValues().formFields.expectedStartDate === 'undefined') {
            message += '&bull;&nbsp;Invalid Expected Start Date specified</br>';
        }
        if (getValues().formFields.anticipatedCompletionDate === '' || getValues().formFields.anticipatedCompletionDate === null || typeof getValues().formFields.anticipatedCompletionDate === 'undefined') {
            message += '&bull;&nbsp;Invalid Anticipated Completion Date specified</br>';
        }

        //if (typeof getValues().formFields.jobs_ID === 'undefined' || getValues().formFields.jobs_ID == '') {
        //    message += '&bull;&nbsp;You must assign the request to a Job</br>';
        //}

        if (message != '') {
            message = "<strong>Mandatory fields missing or invalid data specified</strong><br />" + message;
            toast.error(<span style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: message }}></span>, { alignment: 'top-center', duration: 5 })

            return;
        }
        else {
            SaveOldRequest();
        }
    }

    function getLocalDateTime(date) {
        var year = date.getFullYear();

        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        let myDatePart = year + '-' + month + '-' + day;
        let myTimePart = new Date().toLocaleTimeString([], { hour12: false });

        const myDate = new Date(myDatePart + ' ' + myTimePart);
        const userTimezoneOffset = myDate.getTimezoneOffset() * 60000;

        return new Date(myDate.getTime() - userTimezoneOffset);
    }

    function SaveOldRequest() {
        let postdata = getValues().formFields;
        postdata.RequestStatus = 'Awarded';
        postdata.RequestDate = getLocalDateTime(new Date());
        postdata.Contractors_ID = getValues().formFields.contractors_ID;
        postdata.Contacts_ID = getValues().formFields.contacts_ID;
        postdata.bidDate = getLocalDateTime(getValues().formFields.bidDate);
        postdata.awardDate = getLocalDateTime(getValues().formFields.awardDate);
        postdata.expectedStartDate = getLocalDateTime(getValues().formFields.expectedStartDate);
        postdata.anticipatedCompletionDate = getLocalDateTime(getValues().formFields.anticipatedCompletionDate);
        postdata.DollarsPerHourApprentice = getValues().formFields.dollarsPerHour;
        postdata.JobNum = getValues().formFields.jobNum;
        postdata.awardNotificationSent = getLocalDateTime(getValues().formFields.awardDate);

        //if (state.prevailingWageJob === 'YES') {
        //    postdata.prevailingWageJob = 1;
        //}
        //else {
        //    postdata.prevailingWageJob = 0;
        //}
        //postdata.Creator = userInfo.fullName;

/*        console.log(postdata);*/

        Axios.post('/api/InsertOldRequest', postdata
        ).then(async response => {
            console.log(response.data);

            let postdata = {
                RequestID: response.data,
                MCACMember: userInfo.mcacMember
                //Username: userInfo.fullName
            }

            ClearScreenFields();

            toast.success('New Request Submitted Successfully');
            
        }).catch(error => {
            toast.error("Problem encountered saving old request.");
            console.log(error);
        });
    }

    return (
        <div>
            <Card className="local777Card">
                <Card.Header><span>Add Old Market Recovery Request (* indicates required field)</span></Card.Header>
                <Card.Body>
                    <form>
                        <div className="row">
                            <div className="form-group col-lg-2">
                                <label className="form-label">Bid Date <span className="color-pink">*</span></label>
                                <Controller
                                    name="formFields.bidDate"
                                    control={control}
                                    setValue={setValue}
                                    defaultValue={getValues().formFields?.bidDate ? Moment(getValues().formFields.bidDate).toDate() : null}
                                    render={() =>
                                        <DatePicker
                                            selected={getValues().formFields?.bidDate ? Moment(getValues().formFields.bidDate).toDate() : null}
                                            onChange={date => setValue('formFields.bidDate', date)}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            } />
                                    }
                                />
                            </div>
                            <div className="form-group col-lg-10">

                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Contractor <span className="color-pink">*</span></label><br />
                                <Select
                                    {...register("formFields.contractors_ID")}
                                    value={getValues().formFields?.contractors_ID}
                                    size="large" placeholder="Select Contractor"
                                    className="form-control-custom"
                                    showSearch
                                    optionFilterProp="children"
                                    onSearch={onSearch}
                                    onChange={onContractorChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                >
                                    {/*                    <Option value="jack">Jack</Option>*/}
                                    <option value='' />
                                    {state.allContractors.map(c => <Option value={c.contractors_ID}>{c.name}</Option>)}
                                </Select>
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">Contact <span className="color-pink">*</span></label><br />
                                <Select
                                    {...register("formFields.contacts_ID")}
                                    disabled={state.contactsDisabled}
                                    size="large" placeholder={state.contactsPlaceHolder}
                                    className="form-control-custom"
                                    showSearch
                                    optionFilterProp="children"
                                    onChange={onContactChange}
                                    filterOption={(input, option) =>
                                        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false
                                    }
                                    value={state.selectedContactId}
                                >
                                    {state.contractorContacts.map((con, idx) => <option key={idx} value={con.contacts_ID}>{con.contactName}</option>)}
                                </Select>

                                {/*<select className="form-control-custom" disabled={state.contactsDisabled}>*/}
                                {/*    <option value='' />*/}
                                {/*    {state.contractorContacts.map((con, idx) => <option key={idx} value={con.Contacts_ID}>{con.contactName}</option>)}*/}
                                {/*</select>*/}
                            </div>
                            <div className="form-group col-lg-4">

                            </div>

                            <div className="form-group col-lg-12">
                                &nbsp;
                            </div>

                            <div className="form-group col-lg-12">
                                <label className="form-label color-dark-blue">JOB INFORMATION</label>
                            </div>

                            <div className="form-group col-lg-2">
                                <label className="form-label">Job #</label>
                                <input name="jobName" className="form-control-custom" {...register('formFields.jobNum')} />
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">Job Name</label>
                                <input type="text" {...register("formFields.projectName")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-6">
                                <label className="form-label">Job Details</label>
                                <input type="text" {...register("formFields.projectDetails")} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-4">
                                <label className="form-label">Jobsite Address</label>
                                <input type="text" {...register("formFields.jobsiteAddress")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-4">
                                <label className="form-label">City</label>
                                <input type="text" {...register("formFields.city")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-2">
                                <label className="form-label">State</label>
                                <select className="form-control-custom" {...register("formFields.state")}>
                                    <option></option>
                                    {state.states.map((state, idx) => <option key={idx} value={state.abbreviation}>{state.name}</option>)}
                                </select>
                            </div>
                            <div className="form-group col-lg-2">
                                <label className="form-label">Zip</label>
                                <input type="text" {...register("formFields.zip")} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-2">
                                <label className="form-label">Requested Hours</label>
                                <input type="text" {...register("formFields.requestedHours")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-2">
                                <label className="form-label">Type of Hours</label>
                                <select className="form-control-custom" {...register("formFields.typeOfHours")}>
                                    <option></option>
                                    <option>Plumbing</option>
                                    <option>HVAC</option>
                                    <option>Mechanical</option>
                                    <option>Plumbing & HVAC</option>
                                    <option>Plumbing & Mechanical</option>
                                    <option>HVAC Service</option>
                                    <option>HVAC & Mechanical</option>
                                </select>
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Awarding Party</label>
                                <input type="text" {...register("formFields.awardingParty")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Contact Person</label>
                                <input type="text" {...register("formFields.jobsiteContactPerson")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-2">
                                <label className="form-label">Contact Phone #</label>
                                <input type="text" {...register("formFields.jobsiteContactPhone")} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-12">
                                <label className="form-label">Description</label>
                                <textarea name="description" rows="4" cols="50" {...register("formFields.workDescription")} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-12">
                                &nbsp;
                            </div>

                            <div className="form-group col-lg-6">
                                <label className="form-label color-dark-blue">UNION BIDDERS</label>
                            </div>
                            <div className="form-group col-lg-6">
                                <label className="form-label color-dark-blue">NON UNION BIDDERS</label>
                            </div>

                            <div className="form-group col-lg-6">
                                <input type="text" {...register("formFields.unionBidder1")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-6">
                                <input type="text" {...register("formFields.nonUnionBidder1")} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-6">
                                <input type="text" {...register("formFields.unionBidder2")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-6">
                                <input type="text" {...register("formFields.nonUnionBidder2")} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-6">
                                <input type="text" {...register("formFields.unionBidder3")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-6">
                                <input type="text" {...register("formFields.nonUnionBidder3")} className="form-control-custom" />
                            </div>

                            <div className="form-group col-lg-12">
                                &nbsp;
                            </div>

                            <div className="form-group col-lg-12">
                                <label className="form-label color-dark-blue">HOURS & DATES</label>
                            </div>

                            <div className="form-group col-lg-3">
                                <label className="form-label">Hours Granted</label>
                                <input type="text" {...register("formFields.grantedHours")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">$/Hour</label>
                                <input type="text" {...register("formFields.dollarsPerHour")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Granted By</label>
                                <input type="text" {...register("formFields.grantedBy")} className="form-control-custom" />
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Hours Paid Manually</label>
                                <input type="text" {...register("formFields.hoursPaidManually")} className="form-control-custom" />
                                {/*<label className="form-label">Assign To Job</label>*/}
                                {/*<Select*/}
                                {/*    {...register("formFields.jobs_ID")}*/}
                                {/*    size="large" placeholder="Select Job"*/}
                                {/*    className="form-control-custom"*/}
                                {/*    showSearch*/}
                                {/*    optionFilterProp="children"*/}
                                {/*    onSearch={onSearch}*/}
                                {/*    onChange={onJobChange}*/}
                                {/*    filterOption={(input, option) =>*/}
                                {/*        option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 || false*/}
                                {/*    }*/}
                                {/*>*/}
                                {/*    <option value='' />*/}
                                {/*    {state.allJobs?.map(c => <Option value={c.jobs_ID}>{c.jobName}</Option>)}*/}
                                {/*</Select>*/}
                            </div>

                            <div className="form-group col-lg-3">
                                <label className="form-label">Award Date</label>
                                <Controller
                                    name="formFields.awardDate"
                                    control={control}
                                    setValue={setValue}
                                    defaultValue={getValues().formFields?.awardDate ? Moment(getValues().formFields.awardDate).toDate() : null}
                                    render={() =>
                                        <DatePicker
                                            selected={getValues().formFields?.awardDate ? Moment(getValues().formFields.awardDate).toDate() : null}
                                            onChange={date => setValue('formFields.awardDate', date)}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            } />
                                    }
                                />
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Expected Start Date</label>
                                <Controller
                                    name="formFields.expectedStartDate"
                                    control={control}
                                    setValue={setValue}
                                    defaultValue={getValues().formFields?.expectedStartDate ? Moment(getValues().formFields.expectedStartDate).toDate() : null}
                                    render={() =>
                                        <DatePicker
                                            selected={getValues().formFields?.expectedStartDate ? Moment(getValues().formFields.expectedStartDate).toDate() : null}
                                            onChange={date => setValue('formFields.expectedStartDate', date)}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            } />
                                    }
                                />
                            </div>
                            <div className="form-group col-lg-3">
                                <label className="form-label">Anticipated Completion Date</label>
                                <Controller
                                    name="formFields.anticipatedCompletionDate"
                                    control={control}
                                    setValue={setValue}
                                    defaultValue={getValues().formFields?.anticipatedCompletionDate ? Moment(getValues().formFields.anticipatedCompletionDate).toDate() : null}
                                    render={() =>
                                        <DatePicker
                                            selected={getValues().formFields?.anticipatedCompletionDate ? Moment(getValues().formFields.anticipatedCompletionDate).toDate() : null}
                                            onChange={date => setValue('formFields.anticipatedCompletionDate', date)}
                                            className="form-control-custom"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={
                                                <NumberFormat format="##/##/####" mask="_" />
                                            } />
                                    }
                                />
                            </div>
                        </div>
                    </form>
                    <div className="modal-footer">
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-submit margin-left-15 float-left mt-3" onClick={handleAddRequest}>Add Request</button>&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                </Card.Body>
            </Card>
            
        </div>
    );
}